import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public activeLang: string;
  private readonly languageChangedSubject = new Subject<string>();

  public get onLanguageChanges$(): Observable<string> {
    return this.languageChangedSubject.asObservable();
  }

  constructor(private readonly translateService: TranslateService) {
    this.initLanguage();
  }

  public initLanguage() {
    this.activeLang = localStorage.getItem('lang');
    if (!this.activeLang) {
      this.activeLang = environment.defaultLocale;
      localStorage.setItem('lang', this.activeLang);
    }
    this.translateService.use(this.activeLang);
    this.languageChangedSubject.next(this.activeLang);
  }

  public toggleLang(lang: string) {
    this.activeLang = lang;
    localStorage.setItem('lang', this.activeLang);
    this.translateService.use(this.activeLang);
    this.languageChangedSubject.next(this.activeLang);
  }
}
