<div class="container" [class.openned]="isOpenned">
  <app-call-back>
    <div class="header">
      <a class="tel" [href]="contacts[0].href" target="_blank">{{
        contacts[0].title
      }}</a>
      <button class="toggler" (click)="isOpenned = !isOpenned">
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893Z"
            fill="#383838"
          />
        </svg>
      </button>
      <a [href]="contacts[0].href" class="phone-icon" target="_blank">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.1458 16.8252C19.8505 15.5121 18.3438 14.6962 18.3438 14.6962C17.3426 14.0448 16.341 13.3935 15.34 12.7421C14.3746 12.1139 13.7765 12.3562 13.1926 12.8977L18.2469 17.9956C18.5682 17.6733 18.8691 17.2858 19.1458 16.8252Z"
            fill="#00A8C9"
          />
          <path
            d="M6.68358 3.77698C6.00724 2.74754 4.79924 -0.0570785 3.14309 0.841777C2.72041 1.09817 2.3598 1.37362 2.05542 1.66481L7.07458 6.72738C7.76777 5.80087 7.52584 5.05878 6.68358 3.77698Z"
            fill="#00A8C9"
          />
          <path
            d="M6.34928 7.49584L1.37187 2.47559C-0.990094 6.04683 3.1271 11.3037 5.70334 14.0212C8.3109 16.7716 13.7692 20.943 17.4192 18.6608L12.464 13.6626C10.3947 15.6478 4.38289 9.52376 6.34928 7.49584Z"
            fill="#00A8C9"
          />
        </svg>
      </a>
    </div>
  </app-call-back>
  <p class="free-call">{{ content.p | translate }}</p>
</div>
