import { HostListener, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  public scrollTop: number;
  public winWidth: number;
  public winHeight: number;
  public wheelDelta: number;

  private readonly scrollYChangedSubject = new Subject<number>();
  private readonly onWheelDeltaChangedSubject = new Subject<number>();

  constructor() {
    this.setSize();
    this.scrollYChangedSubject.next();
    this.onWheelDeltaChangedSubject.next();
  }

  public get scroll$(): Observable<number> {
    return this.scrollYChangedSubject.asObservable();
  }
  public get wheel$(): Observable<number> {
    return this.onWheelDeltaChangedSubject.asObservable();
  }

  public setSize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
  }

  public onScroll() {
    this.scrollTop = document.documentElement.scrollTop;
    this.scrollYChangedSubject.next(this.scrollTop);
  }

  public onMouseWheel(e) {
    this.wheelDelta = e.deltaY || e.detail || e.wheelDelta;
    this.onWheelDeltaChangedSubject.next(this.wheelDelta);
  }

  /* helpers */

  public getOffsetTop(id) {
    const offsetTop = document.getElementById(id).offsetTop;
    return offsetTop;
  }

  public isScrolledTo(point) {
    return this.scrollTop > point;
  }
}
