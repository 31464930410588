import { TabComponent } from './tabs/tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { AsideComponent } from './aside/aside.component';
import { AppStoreComponent } from './app-store/app-store.component';
import { PlayMarketComponent } from './play-market/play-market.component';
import { ButtonComponent } from './button/button.component';
import { AnimatedSvgComponent } from './animated-svg/animated-svg.component';
import { GetAppComponent } from './get-app/get-app.component';
import { SendEmailModalComponent } from './send-email-modal/send-email-modal.component';
import { ToggleLanguageComponent } from './toggle-language/toggle-language.component';
import { CallBackComponent } from './call-back/call-back.component';
import { BonusComponent } from './bonus/bonus.component';
import { FORMS_COMPONENTS } from './forms';
import { CallBackDesktopComponent } from './call-back-desktop/call-back-desktop.component';
import { MobileContentComponent } from './mobile-content/mobile-content.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { FaqModalComponent } from './faq-modal/faq-modal.component';
import { FaqComponent } from './faq/faq.component';
import { BranchesComponent } from './branches/branches.component';
import { SpoilerComponent } from './spoiler/spoiler.component';

export const COMPONENTS = [
  ...FORMS_COMPONENTS,
  TabComponent,
  TabsComponent,
  AsideComponent,
  AppStoreComponent,
  PlayMarketComponent,
  ButtonComponent,
  AnimatedSvgComponent,
  GetAppComponent,
  SendEmailModalComponent,
  ToggleLanguageComponent,
  CallBackComponent,
  BonusComponent,
  CallBackDesktopComponent,
  MobileContentComponent,
  InfoModalComponent,
  FaqModalComponent,
  FaqComponent,
  BranchesComponent,
  SpoilerComponent,
];
