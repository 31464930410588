<a [href]="link" target="_blank" [title]="title | translate">
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.3179 33.314C39.8037 34.5137 39.161 35.7133 38.4326 36.7845C37.2329 38.6269 35.5191 40.9406 33.3768 40.9406C31.4915 40.9406 31.0202 39.698 28.4495 39.7409C25.8787 39.7409 25.3645 40.9834 23.4793 40.9406C21.3798 40.9406 19.7517 38.8411 18.552 36.9987C15.1671 31.8572 14.8244 25.773 16.881 22.6024C18.1664 20.4601 20.4372 19.1319 22.9223 19.0033C25.1503 19.0033 26.5642 20.2459 28.4495 20.2459C30.3347 20.2459 31.363 19.0033 33.9338 19.0033C36.1189 19.089 38.1327 20.1602 39.4609 21.9169C36.4188 23.6307 35.3477 27.444 37.0615 30.4861C37.8328 31.8143 38.9468 32.7998 40.3179 33.314Z"
      fill="#717171"
    />
    <path
      d="M32.007 16.9038C33.0781 15.6184 33.5923 13.9046 33.4209 12.2336C31.7071 12.4478 30.1218 13.2619 29.0077 14.5901C27.8937 15.8326 27.3796 17.5036 27.551 19.1318C29.3077 19.1318 30.9358 18.2749 32.007 16.9038Z"
      fill="#717171"
    />
    <rect x="0.5" y="0.5" width="55" height="55" rx="5.5" stroke="#717171" />
  </svg>

  <ng-content></ng-content>
</a>
