<nav>
  <ul>
    <li
      *ngFor="let tab of tabsRefsList"
      (click)="selectTab(tab)"
      [class.active]="tab?.active"
    >
      <a *ngIf="!tab.tabContent.isLogo" href="#">{{
        tab?.tabContent.title | translate
      }}</a>
      <app-logo *ngIf="tab.tabContent.isLogo"></app-logo>
    </li>
  </ul>
</nav>

<app-tab
  *ngFor="let item of content"
  [tabContent]="item"
  (click$)="onTabClick($event)"
  [class]="item.id"
>
</app-tab>
