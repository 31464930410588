import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IInput } from 'src/app/core/models';

@Component({
  selector: 'app-form-controls',
  templateUrl: './form-controls.component.html',
})
export class FormControlsComponent {
  @Input() public readonly conf: IInput;
  @Input() public readonly form: FormGroup;
}
