import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { finalize } from 'rxjs/operators';
import { Validator } from 'class-validator';

import {
  IInput,
  IButton,
  ISendEmailDto,
  ISendEmailConf,
  CONTACT_FORM_CONF,
  SUCCESS_MESSAGE,
  FAILED_MESSAGE,
} from 'src/app/core/models';
import { SendEmailService } from 'src/app/core/services/send-email.service';

@Component({
  selector: 'app-send-email-form',
  templateUrl: './send-email-form.component.html',
  styleUrls: ['./send-email-form.component.scss'],
})
export class SendEmailFormComponent implements OnInit {
  public readonly conf: ISendEmailConf = CONTACT_FORM_CONF;

  public readonly failedMsg: string = FAILED_MESSAGE;
  public readonly successMsg: string = SUCCESS_MESSAGE;

  public controlsConf: IInput[];
  public title: string;
  public buttonConf: IButton;
  public isFailed: boolean;
  public isAlreadySent: boolean;
  public isLoading: boolean;
  public form: FormGroup;

  protected readonly validator = new Validator();

  protected get dto(): ISendEmailDto {
    const dto: ISendEmailDto = {
      email: this.form.controls?.email?.value,
      name: this.form.controls?.name?.value,
      reason: this.form.controls?.subject?.value,
    };

    const message: string = this.form.controls?.message?.value;
    if (message) {
      dto.message = message;
    }

    return dto;
  }

  constructor(protected readonly emailService: SendEmailService) {}

  ngOnInit() {
    this.initForm();
    this.initData();
  }

  public submit() {
    this.isLoading = true;

    this.emailService
      .contact(this.dto)
      .pipe(
        finalize(() => {
          this.isAlreadySent = true;
          this.isLoading = false;
        }),
      )
      .subscribe(({ success }) =>
        success ? this.successFeedback() : this.failFeedback(),
      );
  }

  protected initData() {
    this.controlsConf = this.conf?.controlsConf;
    this.title = this.conf?.title;
    this.buttonConf = this.conf?.buttonConf;
  }

  protected initForm() {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [
        this.isEmailValidate.bind(this),
        Validators.required,
      ]),
      message: new FormControl(null, Validators.required),
      subject: new FormControl(
        this.conf?.defaultSubject ?? null,
        Validators.required,
      ),
    });
  }

  protected successFeedback() {
    this.isFailed = false;
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  protected failFeedback() {
    this.isFailed = true;
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  protected isEmailValidate(control: AbstractControl): { email: true } {
    const email: string = control?.value;

    if (!email || this.validator.isEmail(email)) {
      return null;
    }

    return { email: true };
  }
}
