<div class="faq">
  <h2>{{ content.title | translate }}</h2>
  <div class="items">
    <ul
      *ngFor="
        let list of [content.list1 | translate, content.list2 | translate]
      "
    >
      <app-spoiler
        *ngFor="let item of list"
        [content]="item"
        (open$)="closeAll()"
      ></app-spoiler>
    </ul>
  </div>
</div>
