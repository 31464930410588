import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public isDesktopDevice: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.epicFunction();
  }

  protected epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
}
