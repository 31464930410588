import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ISendEmailDto, ISendEmailResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  private readonly routes = {
    contact: 'contact',
  };

  constructor(private readonly http: HttpClient) {}

  public contact(dto: ISendEmailDto): Observable<ISendEmailResponse> {
    return this.http
      .post<ISendEmailResponse>(
        `${environment.sendEmailApi}/${this.routes.contact}`,
        dto,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('SendEmailService::contact: ', error);

          return of({ success: false });
        }),
      );
  }
}
