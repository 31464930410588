import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { CONTENT_BONUS_NOTATION } from 'src/app/core/models';

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss'],
})
export class BonusComponent implements OnInit {
  @Input() public isDesktop = false;
  @Output() public isOpenned: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  public readonly notation: string = CONTENT_BONUS_NOTATION;
  public activeLanguage: string;
  public isNotationOpenned: boolean;

  constructor(private readonly languageService: LanguageService) {
    this.isNotationOpenned = false;
  }

  ngOnInit() {
    this.activeLanguage = this.languageService.activeLang;
    this.languageService.onLanguageChanges$.subscribe((activeLang) => {
      this.activeLanguage = activeLang;
    });
  }

  public openNotation() {
    this.isDesktop
      ? (this.isNotationOpenned = true)
      : this.isOpenned.emit(true);
  }

  public closeNotation() {
    this.isNotationOpenned = false;
  }
}
