<nav>
  <button class="menu-toggler" (click)="toggleMenu()">
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0625 12H0.9375C0.419719 12 0 11.3284 0 10.5C0 9.67155 0.419719 9 0.9375 9H23.0625C23.5803 9 24 9.67155 24 10.5C24 11.3284 23.5803 12 23.0625 12Z"
        fill="#01A9CA"
      />
      <path
        d="M23.0625 3H0.9375C0.419719 3 0 2.32845 0 1.5C0 0.67155 0.419719 0 0.9375 0H23.0625C23.5803 0 24 0.67155 24 1.5C24 2.32845 23.5803 3 23.0625 3Z"
        fill="#01A9CA"
      />
      <path
        d="M23.0625 21H0.9375C0.419719 21 0 20.3284 0 19.5C0 18.6716 0.419719 18 0.9375 18H23.0625C23.5803 18 24 18.6716 24 19.5C24 20.3284 23.5803 21 23.0625 21Z"
        fill="#01A9CA"
      />
    </svg>
  </button>
  <ul [class.openned]="isMenuOpenned" [class.closed]="!isMenuOpenned">
    <app-toggle-language></app-toggle-language>
    <li *ngFor="let item of content">
      <a (click)="closeMenu()" [href]="'#' + item.id">{{
        item.title | translate
      }}</a>
    </li>
    <app-call-back></app-call-back>
  </ul>
</nav>
