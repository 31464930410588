<div class="info-modal">
  <div class="overlay" (click)="close()"></div>
  <div class="content">
    <div class="image">
      <img src="/assets/icons/info-modal.svg" alt="info" />
    </div>
    <p>{{ content | translate }}</p>
    <app-button [content]="button" (click)="close()"></app-button>
  </div>
</div>
