import { Component, OnInit } from '@angular/core';
import {
  ITab,
  MAIN_CONTENT,
  ISendEmailConf,
  CONTACT_FORM_CONF,
} from 'src/app/core/models';
import { NavService } from 'src/app/core/services/nav.service';
import { ScrollService } from 'src/app/core/services/scroll.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements OnInit {
  public readonly content: Array<ITab> = MAIN_CONTENT;
  public readonly conf: ISendEmailConf = CONTACT_FORM_CONF;
  public isInfoOpen: boolean;
  public isMenuOpenned: boolean;
  public isBonusHidden: boolean;
  constructor(
    private readonly navService: NavService,
    private readonly scrollService: ScrollService
  ) {}

  ngOnInit() {
    this.navService.onMenuChanges$.subscribe((res) => {
      this.isMenuOpenned = res;
    });
    this.scrollService.scroll$.subscribe(scrollTop => {
      this.isBonusHidden = this.canHide(this.scrollService.getOffsetTop('footer') - scrollTop, this.scrollService.winHeight);
    });
  }

  public closeMenu() {
    this.navService.closeMenu();
  }

  public toggleInfo(event) {
    this.isInfoOpen = event;
  }

  public closeInfo(event) {
    this.isInfoOpen = event;
  }

  private canHide(offset: number, winHeight: number): boolean {
    return offset - winHeight < 0;
  }

}
