<footer [class.openned]="isFooterOpenned" id="footer">
  <section class="container">
    <h2>{{ content.h2 | translate }}</h2>
    <button
      class="toggler"
      *ngIf="withButton"
      (click)="isFooterOpenned = !isFooterOpenned"
    >
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2929 6.70711C10.6834 7.09763 11.3166 7.09763 11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289L6.70711 0.292893C6.31658 -0.0976311 5.68342 -0.0976311 5.29289 0.292893L0.292893 5.29289C-0.0976311 5.68342 -0.0976311 6.31658 0.292893 6.70711C0.683418 7.09763 1.31658 7.09763 1.70711 6.70711L6 2.41421L10.2929 6.70711Z"
          fill="white"
        />
      </svg>
    </button>
    <div class="text" *ngIf="!withButton">
      <p>
        <ng-container *ngFor="let item of content.textItems">
          <span>{{ item.span | translate }} </span>
          <a
            *ngIf="item.link"
            [href]="item.link.href"
            target="_blank"
            rel="noopener noreferrer"
            >{{ item.link.title }}</a
          >
        </ng-container>
      </p>
    </div>
    <ul class="social">
      <li>
        <a
          [href]="content.socials[0].href"
          target="_blank"
          [title]="content.socials[0].title"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M9.60539 0.0314941H4.39468C1.97766 0.0314941 0.0314941 1.97766 0.0314941 4.39468V9.63678C0.0314941 12.0538 1.97766 14 4.39468 14H9.63678C12.0538 14 14 12.0538 14 9.63678V4.39468C13.9686 1.97766 12.0224 0.0314941 9.60539 0.0314941ZM12.6816 9.60539C12.6816 11.3004 11.3004 12.6502 9.63678 12.6502H4.39468C2.69963 12.6502 1.34987 11.269 1.34987 9.60539V4.39468C1.34987 2.69963 2.73102 1.34987 4.39468 1.34987H9.63678C11.3318 1.34987 12.6816 2.73102 12.6816 4.39468V9.60539Z"
                fill="white"
              />
              <path
                d="M7 3.5C5.07658 3.5 3.5 5.07658 3.5 7C3.5 8.92343 5.07658 10.5 7 10.5C8.92342 10.5 10.5 8.92343 10.5 7C10.5 5.07658 8.95495 3.5 7 3.5ZM7 9.20721C5.8018 9.20721 4.82432 8.22973 4.82432 7.03153C4.82432 5.83333 5.8018 4.85586 7 4.85586C8.1982 4.85586 9.17567 5.83333 9.17567 7.03153C9.2072 8.1982 8.1982 9.20721 7 9.20721Z"
                fill="white"
              />
              <path
                d="M10.7838 3.72085C11.045 3.72085 11.2567 3.50909 11.2567 3.24788C11.2567 2.98666 11.045 2.7749 10.7838 2.7749C10.5225 2.7749 10.3108 2.98666 10.3108 3.24788C10.3108 3.50909 10.5225 3.72085 10.7838 3.72085Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
      </li>
      <li>
        <a
          [href]="content.socials[1].href"
          target="_blank"
          [title]="content.socials[1].title"
        >
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7071 3.49041C15.7071 3.64384 15.7071 3.79726 15.7071 3.95069C15.7071 8.63014 12.0824 14 5.49555 14C3.46882 14 1.598 13.4247 0 12.4274C0.272828 12.4658 0.584633 12.4658 0.857461 12.4658C2.53341 12.4658 4.09243 11.8904 5.30067 10.9699C3.74165 10.9315 2.41648 9.93425 1.94878 8.51507C2.18263 8.55342 2.37751 8.59178 2.61136 8.59178C2.92316 8.59178 3.27394 8.55342 3.54677 8.47671C1.9098 8.16986 0.662584 6.71233 0.662584 5.02466V4.9863C1.16927 5.17808 1.7539 5.33151 2.33853 5.36986C1.36414 4.75616 0.740534 3.64384 0.740534 2.41644C0.740534 1.76438 0.935412 1.15068 1.20824 0.652055C2.96214 2.8 5.61247 4.18082 8.61359 4.33425C8.53563 4.06575 8.53563 3.79726 8.53563 3.52877C8.53563 1.5726 10.1336 0 12.1214 0C13.1347 0 14.0702 0.421918 14.7327 1.11233C15.5512 0.958904 16.3307 0.652055 16.9933 0.268493C16.7205 1.07397 16.1748 1.80274 15.4343 2.22466C16.1748 2.14795 16.8374 1.95616 17.5 1.68767C16.9933 2.37808 16.4087 2.99178 15.7071 3.49041Z"
              fill="white"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          [href]="content.socials[2].href"
          target="_blank"
          [title]="content.socials[2].title"
        >
          <svg
            width="21"
            height="14"
            viewBox="0 0 21 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.1238 2.22353C19.8785 1.15294 18.9791 0.329412 17.9979 0.247059C15.4631 0 12.9284 0 10.4754 0C8.02245 0 5.48771 0 3.03474 0.247059C1.97178 0.411765 1.07236 1.15294 0.827063 2.22353C0.5 3.78824 0.5 5.43529 0.5 7C0.5 8.56471 0.5 10.2118 0.827063 11.7765C1.07236 12.8471 1.97178 13.6706 2.95297 13.7529C5.40595 14 7.94069 14 10.3937 14C12.8466 14 15.3814 14 17.8343 13.7529C18.8973 13.6706 19.715 12.8471 19.9603 11.7765C20.2873 10.2941 20.2873 8.56471 20.2873 7C20.4509 5.43529 20.4509 3.78824 20.1238 2.22353ZM8.67658 9.71765V3.29412L14.2367 6.50588L8.67658 9.71765Z"
              fill="white"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          [href]="content.socials[3].href"
          target="_blank"
          [title]="content.socials[3].title"
        >
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.4727 8.55555V14H12.2833V8.98765C12.2833 7.69136 11.8403 6.82716 10.6885 6.82716C9.80257 6.82716 9.271 7.4321 9.00521 7.95062C8.91662 8.12346 8.91661 8.46914 8.91661 8.7284V14H5.72716C5.72716 14 5.72716 5.44444 5.72716 4.58025H8.91661V5.87654C9.35959 5.27161 10.0684 4.32099 11.8403 4.32099C13.878 4.32099 15.4727 5.7037 15.4727 8.55555ZM2.36051 0C1.20877 0 0.5 0.691358 0.5 1.64198C0.5 2.50617 1.20877 3.28395 2.27192 3.28395C3.42367 3.28395 4.13243 2.59259 4.13243 1.64198C4.13243 0.691358 3.42367 0 2.36051 0ZM0.677192 14H3.86665V4.58025H0.677192V14Z"
              fill="white"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          [href]="content.socials[4].href"
          target="_blank"
          [title]="content.socials[4].title"
        >
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6665 5.86255C10.6227 5.86255 10.5571 5.86255 10.5134 5.86255C9.96648 5.92817 9.5071 6.2563 9.26648 6.73755C9.0696 7.1313 9.0696 7.61255 9.2446 8.0063C9.26648 8.07192 9.31023 8.13755 9.35398 8.20317C9.4196 8.33442 9.52898 8.4438 9.61648 8.5313C9.72585 8.6188 9.83523 8.7063 9.96648 8.77192H9.98835C10.1196 8.83755 10.2509 8.85942 10.3821 8.8813H10.4259C10.4915 8.8813 10.5571 8.90317 10.6446 8.90317C10.7102 8.90317 10.754 8.90317 10.8196 8.90317H10.8634L11.0165 8.8813C11.4977 8.77192 11.8915 8.46567 12.0884 8.02817V8.0063C12.1102 7.94067 12.1321 7.87505 12.154 7.80942C12.1759 7.7438 12.1759 7.67817 12.1977 7.61255V7.5688C12.1977 7.50317 12.1977 7.43755 12.1977 7.37192C12.1759 6.54067 11.4977 5.88442 10.6665 5.86255Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7227 0H2.22266C1.26016 0 0.472656 0.7875 0.472656 1.75V12.25C0.472656 13.2125 1.26016 14 2.22266 14H12.7227C13.6852 14 14.4727 13.2125 14.4727 12.25V1.75C14.4727 0.7875 13.6852 0 12.7227 0ZM3.90703 8.88125C4.60703 9.03437 5.35078 8.68437 5.65703 8.02812H5.67891H6.88203C6.57578 9.23125 5.50391 10.0625 4.27891 10.0844C2.76953 10.0844 1.56641 8.88125 1.56641 7.39375C1.56641 6.0375 2.59453 4.87813 3.95078 4.725C5.30703 4.57188 6.57578 5.44688 6.88203 6.75938H5.67891C5.39453 6.08125 4.67266 5.70937 3.95078 5.8625C3.22891 6.01562 2.72578 6.62812 2.70391 7.35C2.68203 8.07187 3.20703 8.72812 3.90703 8.88125ZM12.307 9.5375C11.3664 10.2375 10.0977 10.2594 9.13516 9.58125V9.86562H8.04141V2.16562H9.13516V5.1625C9.52891 4.9 9.98828 4.725 10.4477 4.70312H10.6445C11.8258 4.68125 12.8539 5.425 13.2477 6.51875C13.6195 7.6125 13.2477 8.8375 12.307 9.5375Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                  transform="translate(0.472656)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </li>
    </ul>
    <p class="copyright">
      <span>{{ content.copyright.span }}</span
      ><span>{{ currentDate | date: 'yyyy' }}, </span>
      <a
        [href]="content.copyright.link.href"
        target="_blank"
        rel="noopener noreferrer"
        >{{ content.copyright.link.title }}</a
      ><span>{{ content.copyright.span1 }} </span>
      <ng-container *ngIf="withButton || isFooterOpenned">
        <span class="extra-text" *ngFor="let item of content.textItems">
          <span>{{ item.span | translate }}</span>
          <a
            *ngIf="item.link"
            [href]="item.link.href"
            target="_blank"
            rel="noopener noreferrer"
            >{{ item.link.title | translate }}</a
          >
        </span>
      </ng-container>
    </p>
  </section>
</footer>
