<ngx-smart-modal #myModal identifier="myModal">
  <app-send-email-form></app-send-email-form>

  <app-call-back>
    <h3>{{ 'PHONE' | translate }}</h3>
    <a
      class="tel"
      [title]="contactPhone[0].title"
      [href]="contactPhone[0].href"
      >{{ contactPhone[0].title }}</a
    >
  </app-call-back>
</ngx-smart-modal>

<app-animated-svg (click)="openModal()"></app-animated-svg>
