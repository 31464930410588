import { Component, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ScrollService } from './core/services/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public isDesktopDevice: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private readonly scrollService: ScrollService
  ) {
    this.epicFunction();
  }

  @HostListener('window:scroll', ['$event.target'])
  public onScroll() {
    this.scrollService.onScroll();
  }

  @HostListener('window:mousewheel', ['$event'])
  public onMouseWheel(e) {
    this.scrollService.onMouseWheel(e);
  }

  @HostListener('window:resize')
  public onResize() {
    this.scrollService.setSize();
  }

  protected epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
}
