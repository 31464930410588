<a [href]="link" target="_blank" [title]="title | translate"
  ><svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3239 14.3534C15.9811 14.8247 15.7669 15.3817 15.7669 15.9816V39.8469C15.7669 40.4039 15.9383 40.9181 16.2382 41.3894L29.2206 27.85L16.3239 14.3534Z"
      fill="#717171"
    />
    <path
      d="M30.4202 26.6075L34.6191 22.1943L19.7943 13.6251C19.1517 13.2395 18.4233 13.1538 17.7377 13.368L30.4202 26.6075Z"
      fill="#717171"
    />
    <path
      d="M30.4198 29.0927L17.6088 42.4179C17.8658 42.5035 18.1658 42.5464 18.4657 42.5464C18.937 42.5464 19.4083 42.4179 19.7939 42.1608L34.7044 33.5487L30.4198 29.0927Z"
      fill="#717171"
    />
    <path
      d="M40.4892 25.5792L36.1617 23.0941L31.62 27.85L36.2903 32.6917L40.5321 30.2494C41.389 29.7781 41.9031 28.8784 41.9031 27.8929C41.8174 26.9503 41.3461 26.0934 40.4892 25.5792Z"
      fill="#717171"
    />
    <rect x="0.5" y="0.5" width="55" height="55" rx="5.5" stroke="#717171" />
  </svg>
</a>
