import { Component, Input, OnInit } from '@angular/core';
import { ITab, MAIN_CONTENT } from 'src/app/core/models';
import { NavService } from 'src/app/core/services/nav.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public readonly content: ITab[] = MAIN_CONTENT;
  public isMenuOpenned: boolean;

  constructor(public readonly navService: NavService) {}

  ngOnInit() {
    this.navService.onMenuChanges$.subscribe((res) => {
      this.isMenuOpenned = res;
    });
  }

  public closeMenu() {
    this.navService.closeMenu();
  }

  public toggleMenu() {
    this.navService.toggleMenu();
  }
}
