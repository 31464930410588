import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private readonly menuChangedSubject = new Subject<boolean>();

  private isMenuOpen = false;

  public get onMenuChanges$(): Observable<boolean> {
    return this.menuChangedSubject.asObservable();
  }

  constructor() {
    this.menuChangedSubject.next(this.isMenuOpen);
    this.toggleBody();
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuChangedSubject.next(this.isMenuOpen);
    this.toggleBody();
  }

  public closeMenu() {
    this.isMenuOpen = false;
    this.menuChangedSubject.next(this.isMenuOpen);
    this.toggleBody();
  }

  public toggleBody() {
    this.isMenuOpen ? document.body.className = 'hidden' : document.body.className = '';
  }
}
