<div
  class="anim-wrapper"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div
    #typingContainer
    class="typing"
    [ngStyle]="{
      overflow: 'hidden',
      margin: '0 auto'
    }"
  ></div>
  <div
    #reactingContainer
    class="reacting"
    [class.active]="!isTyping || isFormOnFocus"
    [ngStyle]="{
      overflow: 'hidden',
      margin: '0 auto'
    }"
  ></div>
  <div
    #lookingContainer
    class="looking"
    [class.active]="!isTyping || isFormOnFocus"
    [ngStyle]="{
      overflow: 'hidden',
      margin: '0 auto'
    }"
  ></div>
</div>
