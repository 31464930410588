<app-header></app-header>
<main>
  <app-mobile-content
    *ngFor="let item of content"
    [content]="item"
  ></app-mobile-content>
  <app-send-email-form></app-send-email-form>

  <app-faq></app-faq>
  <app-branches></app-branches>
  <app-bonus
    (isOpenned)="toggleInfo($event)"
    [ngClass]="{ hidden: isBonusHidden }"
  ></app-bonus>
</main>
<app-footer></app-footer>

<app-info-modal
  [class.opened]="isInfoOpen"
  (isOpen)="closeInfo($event)"
></app-info-modal>
<div class="overlay" (click)="closeMenu()" [class.show]="isMenuOpenned"></div>
