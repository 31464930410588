<div [formGroup]="form">
  <textarea
    *ngIf="conf?.type === 'textarea'"
    [placeholder]="conf?.placeholder | translate"
    [formControlName]="conf?.name"
  ></textarea>

  <input
    *ngIf="conf?.type !== 'textarea' && conf?.type !== 'select'"
    [type]="conf?.type"
    [placeholder]="conf?.placeholder | translate"
    [formControlName]="conf?.name"
  />
</div>
