<main>
  <app-toggle-language></app-toggle-language>
  <app-call-back-desktop [class]="activeLanguage"></app-call-back-desktop>
  <app-aside></app-aside>
  <app-tabs> </app-tabs>
  <app-send-email-modal></app-send-email-modal>
  <app-footer withButton="true"></app-footer>
  <app-faq-modal></app-faq-modal>
  <app-button
    (click)="openModal()"
    [content]="{ title: faqBtn }"
    class="faq-button"
  >
  </app-button>
</main>
