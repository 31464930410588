import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  IButton,
  BUTTON_OK,
  CONTENT_BONUS_NOTATION,
} from 'src/app/core/models';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
  @Input() public readonly content: string = CONTENT_BONUS_NOTATION;
  @Output() public isOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly button: IButton = BUTTON_OK;

  public close() {
    this.isOpen.emit(false);
  }
}
