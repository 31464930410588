import {
  Component,
  Input,
  AfterViewChecked,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { ITab } from 'src/app/core/models';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit, AfterViewChecked {
  @Input() public active = false;
  @Input() public previous = false;
  @Input() public readonly tabContent: ITab;

  @Output() public readonly click$ = new EventEmitter<ITab>();

  public videoPlayer: HTMLVideoElement;

  public activeLanguage: string;

  constructor(private readonly languageService: LanguageService) {}

  @ViewChild('videoPlayer')
  set mainVideoEl(el: ElementRef) {
    this.videoPlayer = el?.nativeElement;
  }

  ngOnInit() {
    this.activeLanguage = this.languageService.activeLang;
    this.languageService.onLanguageChanges$.subscribe((activeLang) => {
      this.activeLanguage = activeLang;
      this.videoPlayer?.load();
    });
  }
  ngAfterViewChecked() {
    if (this.active && this.videoPlayer) {
      this.videoPlayer.loop = true;
      this.videoPlayer.play();
    }
    if (!this.active && this.videoPlayer) {
      this.videoPlayer.currentTime = 0;
      this.videoPlayer.pause();
    }
  }

  public onClick() {
    this.click$.emit(this.tabContent);
  }

  public getImgPath(): string {
    return 'IMAGES.' + this.tabContent.content.imgKey;
  }

  public getVideoPath(): string {
    return this.tabContent.content.video + '--' + this.activeLanguage + '.mp4';
  }
}
