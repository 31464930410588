<div class="bonus">
  <img
    (click)="openNotation()"
    (mouseenter)="openNotation()"
    (mouseleave)="closeNotation()"
    src="/assets/icons/bonus-{{ activeLanguage }}.svg"
    alt="bonus 5 EUR"
  />
  <div class="notation" [class.openned]="isNotationOpenned">
    <div class="text-wrapper">
      <span *ngIf="!isDesktop" (click)="closeNotation()">&#10006;</span>
      <p>{{ notation | translate }}</p>
    </div>
    <svg
      class="bigger"
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="9" cy="6.5" rx="9" ry="6.5" fill="white" />
    </svg>
    <svg
      class="smaller"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="4" cy="2.5" rx="4" ry="2.5" fill="white" />
    </svg>
  </div>
</div>
