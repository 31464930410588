import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { IButton, TEST_APP_BUTTON } from 'src/app/core/models';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-desktop',
  templateUrl: './desktop.component.html',
})
export class DesktopComponent implements OnInit {
  public readonly buttonContent: IButton = TEST_APP_BUTTON;
  public activeLanguage: string;
  public faqBtn = 'FAQ_BUTTON';

  constructor(
    private readonly languageService: LanguageService,
    private readonly ngxSmartModalService: NgxSmartModalService,
  ) {}

  ngOnInit() {
    this.activeLanguage = this.languageService.activeLang;
    this.languageService.onLanguageChanges$.subscribe((activeLang) => {
      this.activeLanguage = activeLang;
    });
  }

  public openModal() {
    this.ngxSmartModalService.getModal('faq').open();
  }
}
