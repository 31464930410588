import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-store',
  templateUrl: './app-store.component.html',
  styleUrls: ['./app-store.component.scss'],
})
export class AppStoreComponent {
  @Input() public readonly title: string;
  public readonly link = environment.appStoreLink;
}
