<div class="button" [class.disabled]="disabled" *ngIf="!content.href">
  <button class="btn" [type]="content.type" [disabled]="disabled">
    {{ content.title | translate }}
  </button>
</div>
<div class="button" *ngIf="content.href">
  <a class="btn" [href]="content.href" [type]="content.type" target="_blank">
    {{ content.title | translate }}
  </a>
</div>
