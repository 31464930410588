import { Component } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CONTACT_LINKS } from 'src/app/core/models';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss'],
})
export class SendEmailModalComponent {
  public readonly contactPhone = CONTACT_LINKS;

  constructor(private readonly ngxSmartModalService: NgxSmartModalService) {}

  public openModal() {
    this.ngxSmartModalService.getModal('myModal').open();
  }
}
