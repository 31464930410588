<section class="feedback">
  <ng-content></ng-content>
  <div class="content">
    <h4>{{ content.h4 | translate }}</h4>
    <!-- <form>
      <input type="tel" placeholder="+ _ _ _ _ _ _ _ _ _ _ _ _" />
      <button [type]="content.button.type">
        {{ content.button.title | translate }}
      </button>
    </form> -->
    <div class="contacts">
      <a
        *ngFor="let link of contacts"
        [href]="link.href"
        [title]="link.title"
        target="_blank"
      >
        <picture>
          <source
            media="(min-width: 1366px)"
            [srcset]="link.desktopImg || link.img"
          />
          <img [src]="link.img" alt="" />
        </picture>
      </a>
    </div>
  </div>
</section>
