<div class="languages">
  <button
    *ngFor="let lang of languages"
    (click)="toggleLang(lang.value)"
    [class.active]="activeLanguage === lang.value"
    [disabled]="activeLanguage === lang.value"
  >
    {{ lang.title }}
  </button>
</div>
