import { DesktopComponent } from './desktop/desktop.component';
import { MobileComponent } from './mobile/mobile.component';
import { DownloadPageComponent } from './download-page/download-page.component';
import { HomePageComponent } from './home-page/home-page.component';

export const PAGES = [
  DesktopComponent,
  MobileComponent,
  DownloadPageComponent,
  HomePageComponent,
];
