export const GET_APP_BUTTON: IButton = {
  title: 'GET_APP_BUTTON',
  type: 'button',
};

export const WEB_APP_BUTTON: IButton = {
  title: 'WEB_APP_BUTTON',
  href: 'https://pay.elegro.eu/auth/sign-in',
  type: 'link',
};

export const TEST_APP_BUTTON: IButton = {
  title: 'Тест приложения',
  type: 'button',
};

export const CONTENT_BONUS_NOTATION = 'CONTENT_BONUS_NOTATION';
export const CONTACT_LINKS: IContactLink[] = [
  {
    title: '0800217093',
    href: 'tel: 0800217093',
    img: '/assets/icons/phone.svg',
    desktopImg: '/assets/icons/phone--desktop.svg',
    alt: 'phone',
  },
  {
    title: 'Skype',
    href: 'https://join.skype.com/defvsROfZcm4',
    img: '/assets/icons/skype.svg',
    alt: 'skype',
  },
  {
    title: 'telegram',
    href: 'https://t.me/elegrowalletua',
    img: '/assets/icons/telegram.svg',
    alt: 'telegram',
  },
  {
    title: 'viber',
    href: 'https://invite.viber.com/?g2=AQB5ndmsh%2BxllkwB0BUlXvVnOIbZnFPT3iFDUApNaF48BkzJ8kq5ZAH2NAtzPmtV',
    img: '/assets/icons/viber.svg',
    alt: 'viber',
  },
];

export const ASIDE_CONTENT = {
  downloadApp: {
    h2: 'ASIDE_CONTENT.downloadApp.h2',
    button: WEB_APP_BUTTON,
    p1: 'ASIDE_CONTENT.downloadApp.p1',
    p2: 'ASIDE_CONTENT.downloadApp.p2',
  },
  contact: {
    h2: 'ASIDE_CONTENT.contact.h2',
    p: 'ASIDE_CONTENT.contact.p',
    button: GET_APP_BUTTON,
  },
};

export const MAIN_CONTENT: Array<ITab> = [
  {
    id: 'home',
    title: 'home.title',
    isLogo: true,
    content: {
      h2: 'home.content.h2',
      p: 'home.content.p',
      p1: 'home.content.p1',
      p2: 'home.content.p2',
      imgKey: 'main',
    },
    getApp: true,
  },
  {
    id: 'europe_account',
    title: 'europe_account.title',
    content: {
      h2: 'europe_account.content.h2',
      p: 'europe_account.content.p',
      video: '/assets/videos/account',
    },
  },
  {
    id: 'plastic_card',
    title: 'plastic_card.title',
    content: {
      h2: 'plastic_card.content.h2',
      p: 'plastic_card.content.p',
      video: '/assets/videos/perevod',
    },
  },
  {
    id: 'exchange',
    title: 'exchange.title',
    content: {
      h2: 'exchange.content.h2',
      p: 'exchange.content.p',
      video: '/assets/videos/obmen',
    },
  },
  {
    id: 'cashing_out',
    title: 'cashing_out.title',
    content: {
      h2: 'cashing_out.content.h2',
      p: 'cashing_out.content.p',
      video: '/assets/videos/Snyatie',
    },
  },
  {
    id: 'funds_control',
    title: 'funds_control.title',
    content: {
      h2: 'funds_control.content.h2',
      p: 'funds_control.content.p',
      video: '/assets/videos/otslezhivanie',
    },
  },
  {
    id: 'price',
    title: 'price.title',
    content: {
      h2: 'price.content.h2',
      caption: 'price.content.caption',
      table: [
        { title: 'price.content.table.0.title', value: 'EUR 0' },
        { title: 'price.content.table.1.title', value: 'EUR 0' },
        { title: 'price.content.table.2.title', value: '1,5%' },
        { title: 'price.content.table.3.title', value: '0,05%' },
        {
          title: 'price.content.table.4.title',
          value: '1,5%',
        },
        { title: 'price.content.table.5.title', value: '0%' },
        { title: 'price.content.table.6.title', value: '1%' },
        { title: 'price.content.table.7.title', value: '0%' },
      ],
      imgKey: 'price',
    },
  },
  {
    id: 'how-it-works',
    title: 'how-it-works.title',
    content: {
      h2: 'how-it-works.content.h2',
      steps: [
        {
          icons: [
            '/assets/icons/how-it-works/01.svg',
            '/assets/icons/how-it-works/02.svg',
          ],
          desciption: 'how-it-works.content.steps.0',
        },
        {
          icons: [
            '/assets/icons/how-it-works/03.svg',
            '/assets/icons/how-it-works/04.svg',
          ],
          desciption: 'how-it-works.content.steps.1',
        },
        {
          icons: [
            '/assets/icons/how-it-works/05.svg',
            '/assets/icons/how-it-works/06.svg',
          ],
          desciption: 'how-it-works.content.steps.2',
          accomplice: 'how-it-works.content.accomplice',
        },
        {
          icons: [
            '/assets/icons/how-it-works/07.svg',
            '/assets/icons/how-it-works/08.svg',
          ],
          desciption: 'how-it-works.content.steps.3',
        },
      ],
    },
  },
];

export const faq = {
  title: 'faq.title',
  list1: 'faq.list1',
  list2: 'faq.list2',
};

export const branches = {
  title: 'branches.title',
  list: 'branches.list',
};

export const BUTTON_OK: IButton = {
  title: 'OK',
  type: 'button',
};
export interface IContactLink {
  title: string;
  href: string;
  img: string;
  desktopImg?: string;
  alt: string;
}

export const LANGUAGES: ILanguage[] = [
  { value: 'ru', title: 'РУС' },
  { value: 'ua', title: 'УКР' },
  { value: 'en', title: 'ENG' },
];

export const CALL_BACK_BUTTON: IButton = {
  title: 'CALL_BACK_BUTTON',
  type: 'submit',
};

export const CALL_BACK_FORM = {
  h4: 'CALL_BACK_FORM',
  button: CALL_BACK_BUTTON,
  p: 'CALL_BACK_FREE_CALL',
};

export const CONTACT_FORM_BUTTON: IButton = {
  title: 'CONTACT_FORM_BUTTON',
  type: 'submit',
};

export const CONTACT_US_BUTTON: IButton = {
  title: 'Contact us',
};

export const CONTACT_US_CONTROLS: IInput[] = [
  {
    type: 'text',
    placeholder: 'CONTACT_US_CONTROLS.0.placeholder',
    name: 'name',
  },
  {
    type: 'email',
    placeholder: 'CONTACT_US_CONTROLS.1.placeholder',
    name: 'email',
  },
  {
    type: 'text',
    placeholder: 'CONTACT_US_CONTROLS.2.placeholder',
    name: 'subject',
  },
  {
    type: 'textarea',
    placeholder: 'CONTACT_US_CONTROLS.3.placeholder',
    name: 'message',
  },
];

export const CONTACT_FORM_CONF: ISendEmailConf = {
  title: 'CONTACT_FORM_CONF',
  buttonConf: CONTACT_FORM_BUTTON,
  controlsConf: CONTACT_US_CONTROLS,
};

export const SOCIAL_LINKS: ISocialLinks[] = [
  {
    title: 'instagram',
    href: 'https://www.instagram.com/niko_technologies/',
    path: '/assets/icons/footer/Instagram.svg',
  },
  {
    title: 'twitter',
    href: 'https://twitter.com/NikoTech_',
    path: '/assets/icons/footer/Twitter.svg',
  },
  {
    title: 'youtube',
    href: 'https://www.youtube.com/channel/UCgkH-b1e0d_Gh5aKWHTbezA',
    path: '/assets/icons/footer/Youtube.svg',
  },
  {
    title: 'linkedin',
    href: 'https://www.linkedin.com/company/niko-technologies-ou/',
    path: '/assets/icons/footer/Linkedin.svg',
  },
  {
    title: 'cb',
    href: 'https://www.crunchbase.com/organization/niko-technologies',
    path: '/assets/icons/footer/cb.svg',
  },
];
export const CONTENT_FOOTER = {
  h2: 'CONTENT_FOOTER.h2',
  textItems: [
    {
      span: 'CONTENT_FOOTER.textItems.1.text',
      link: {
        title: 'FinCEN',
        href: 'https://www.fincen.gov/msb-registrant-search',
      },
    },
    {
      span: 'CONTENT_FOOTER.textItems.2.text',
      link: {
        title: '31000170855625',
        href: 'https://niko-technologies-public.s3.eu-central-1.amazonaws.com/Niko-Tech-MSB-US-registration.pdf',
      },
    },
    {
      span: 'CONTENT_FOOTER.textItems.3.text',
      link: {
        title: 'FINTRAC',
        href: 'https://www.fintrac-canafe.gc.ca/intro-eng',
      },
    },
    {
      span: 'CONTENT_FOOTER.textItems.4.text',
      link: {
        title: 'M20200419',
        href: 'https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136323038312c227072696d617279536561726368223a7b226f72674e616d65223a224e494b4f20544543484e4f4c4f47494553204fdc222c2273656172636854797065223a317d7d/',
      },
    },
    {
      span: 'CONTENT_FOOTER.textItems.5.text',
      link: {
        title: 'CONTENT_FOOTER.fullMember',
        href: 'https://bank.gov.ua/ua/payments/payment-systems/d46d4d387e24410f9593a9413b1469a8',
      },
    },
    {
      span: 'CONTENT_FOOTER.textItems.6.text',
      link: {
        title: 'Niko Technologies OÜ',
        href: 'https://niko-technologies.eu/',
      },
    },
    {
      span: 'CONTENT_FOOTER.textItems.7.text',
    },
  ],

  socials: SOCIAL_LINKS,
  copyright: {
    span: 'Copyright ©',
    link: {
      title: 'Niko Technologies OÜ',
      href: 'https://niko-technologies.eu/',
    },
    span1: '. All rights reserved.',
  },
};

export const FAILED_MESSAGE = 'FAILED_MESSAGE';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

export interface ISocialLinks {
  title: string;
  path: string;
  href: string;
}

export enum DeviceOSEnum {
  IOS = 'iOS',
  MAC = 'Mac',
  OTHER = 'Other',
}

export interface ILanguage {
  title: string;
  value: string;
}

export enum VideoIdEnum {
  EUROPE_ACCOUNT = 'europe_account',
  PLASTIC_CARD = 'plastic_card',
  EXCHANGE = 'exchange',
  CASHING_OUT = 'cashing_out',
  FUNDS_CONTROL = 'funds_control',
}

export interface IDeviceOS {
  name: DeviceOSEnum;
}

export interface ITab {
  title: string;
  content: ITabContent;
  id: string;
  isLogo?: boolean;
  getApp?: boolean;
}

export interface ITabContent {
  h2: string;
  p?: string;
  p1?: string;
  p2?: string;
  table?: Array<ITableRow>;
  caption?: string;
  imgKey?: string;
  video?: string;
  frame?: string;
  steps?: ITabStep[];
}

export interface ITableRow {
  title: string;
  value: string;
}

export interface ITabStep {
  icons: string[];
  desciption: string;
  accomplice?: string;
}

export interface IImgSrc {
  src: string;
  srcTablet?: string;
  srcDesktop?: string;
  alt: string;
}

export interface IButton {
  href?: string;
  externalLink?: string;
  title: string;
  type?: string;
}

export interface ISendEmailDto {
  email: string;
  name: string;
  reason: string;
  message?: string;
}

export interface ISendEmailResponse {
  success: boolean;
}

export interface ISendEmailConf {
  title: string;
  buttonConf: IButton;
  controlsConf: IInput[];
  defaultSubject?: string;
}

export interface IInput {
  type?: string;
  placeholder: string;
  isHidden?: boolean;
  options?: IOption[];
  name: string;
}

export interface IOption {
  value: string;
  text: string;
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}
