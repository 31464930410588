import {
  Component,
  QueryList,
  ViewChildren,
  AfterViewInit,
  OnInit,
} from '@angular/core';

import { TabComponent } from './tab/tab.component';
import { ITab, MAIN_CONTENT } from 'src/app/core/models';
import { ScrollService } from 'src/app/core/services/scroll.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements OnInit, AfterViewInit {
  @ViewChildren(TabComponent) private readonly tabsQueryList: QueryList<
    TabComponent
  >;

  public readonly content: Array<ITab> = MAIN_CONTENT;

  public readonly tabsRefsList: TabComponent[] = [];
  private activeTabIndex: number;
  private previousTabIndex: number;

  private get tabsVolume(): number {
    return this.tabsRefsList?.length;
  }

  constructor(private readonly scrollService: ScrollService) {}

  ngOnInit() {
    this.scrollService.wheel$.subscribe((wheelScroll) => {
      wheelScroll > 0 ? this.goToNextTab() : this.goToPreviousTab();
    });
  }

  ngAfterViewInit() {
    this.tabsQueryList.forEach((tabRef) => this.tabsRefsList.push(tabRef));
    if (!this.tabsVolume) {
      console.error('ERROR: no tabsRefsList');
      return;
    }

    const activeTab: TabComponent = this.tabsRefsList.find(
      (tabRef) => tabRef.active,
    );

    if (!activeTab) {
      Promise.resolve(true).then(() => this.selectTab(this.tabsRefsList[0]));
    }
  }

  public selectTab(tabRef: TabComponent) {
    this.previousTabIndex = this.getPreviousTabIndex();
    this.activeTabIndex = this.getIndex(tabRef.tabContent);

    this.tabsRefsList.forEach((tab) => {
      tab.active = false;
      tab.previous = false;
    });
    tabRef.active = true;
    if (this.previousTabIndex > this.activeTabIndex) {
      this.tabsRefsList[this.activeTabIndex].previous = true;
    } else {
      if (this.tabsRefsList[this.activeTabIndex]) {
        this.tabsRefsList[this.activeTabIndex].previous = false;
      }
    }
  }

  public onTabClick(content: ITab) {
    this.activeTabIndex = this.getIndex(content);
    this.goToNextTab();
  }

  protected goToNextTab() {
    if (this.activeTabIndex < this.tabsVolume - 1) {
      this.selectTab(this.tabsRefsList[this.activeTabIndex + 1]);
    }
  }

  protected goToPreviousTab() {
    if (this.activeTabIndex > 0) {
      this.selectTab(this.tabsRefsList[this.activeTabIndex - 1]);
    }
  }

  protected getPreviousTabIndex() {
    const index = this.tabsRefsList.findIndex((tab) => tab.active);
    return index < 0 ? 0 : index;
  }

  protected getIndex(content) {
    return this.tabsRefsList.findIndex(
      (tabRef) => tabRef.tabContent?.id === content.id,
    );
  }
}
