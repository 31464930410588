<section class="wrap-container contact-us">
  <h2>{{ title | translate }}</h2>
  <app-animated-svg [isFormOnFocus]="form.touched"></app-animated-svg>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <app-form-controls
      *ngFor="let controlConf of controlsConf"
      [form]="form"
      [conf]="controlConf"
    ></app-form-controls>
    <app-button
      [content]="buttonConf"
      [disabled]="form.invalid || form.pending || isLoading"
    ></app-button>
  </form>
  <div
    class="notifications"
    *ngIf="form.pristine && form.untouched && isAlreadySent"
  >
    <span class="text-danger" *ngIf="isFailed">{{
      failedMsg | translate
    }}</span>
    <span *ngIf="!isFailed">{{ successMsg | translate }}</span>
  </div>
</section>
