import { Component, OnInit, Input } from '@angular/core';
import { CONTENT_FOOTER } from 'src/app/core/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() public readonly withButton: boolean = false;
  public readonly content = CONTENT_FOOTER;
  public isFooterOpenned: boolean;
  public readonly currentDate: Date = new Date();

  ngOnInit() {
    if (this.withButton) {
      this.isFooterOpenned = false;
    }
  }
}
