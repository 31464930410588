export const environment = {
  production: false,
  internalPathes: {
    comingSoonAnimationsScript: '/assets/scripts/coming-soon.js',
  },
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=paydeepp.elegro.io',
  appStoreLink:
    'https://apps.apple.com/gb/app/elegro-crypto-fiat-wallet/id1443206753',
  sendEmailApi: 'https://emails-proxy-stage.niko-tech.eu',
  locales: ['ua', 'ru'],
  defaultLocale: 'ru',
};
