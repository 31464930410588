import { Component } from '@angular/core';
import { ASIDE_CONTENT } from 'src/app/core/models';

@Component({
  selector: 'app-get-app',
  templateUrl: './get-app.component.html',
  styleUrls: ['./get-app.component.scss'],
})
export class GetAppComponent {
  public readonly content = ASIDE_CONTENT;
}
