import { Component } from '@angular/core';
import { branches } from 'src/app/core/models';
import { getGoogleMapsLink } from 'src/app/core/helpers/google-maps-link';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
})
export class BranchesComponent {
  public readonly content = branches;

  public getLink(address: string) {
    return getGoogleMapsLink(address);
  }
}
