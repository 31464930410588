import { Component, OnInit } from '@angular/core';
import { ILanguage, LANGUAGES } from 'src/app/core/models';
import { NavService } from 'src/app/core/services/nav.service';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-toggle-language',
  templateUrl: './toggle-language.component.html',
  styleUrls: ['./toggle-language.component.scss'],
})
export class ToggleLanguageComponent implements OnInit {
  public activeLanguage: string;
  public languages: ILanguage[] = LANGUAGES;

  constructor(
    private readonly navService: NavService,
    private readonly languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.activeLanguage = this.languageService.activeLang;
    this.languageService.onLanguageChanges$.subscribe((activeLang) => {
      this.activeLanguage = activeLang;
    });
  }

  public toggleLang(lang: string) {
    this.languageService.toggleLang(lang);
    this.navService.closeMenu();
  }
}
