import { Component, OnInit } from '@angular/core';
import {
  CALL_BACK_FORM,
  IContactLink,
  CONTACT_LINKS,
} from 'src/app/core/models';

@Component({
  selector: 'app-call-back-desktop',
  templateUrl: './call-back-desktop.component.html',
  styleUrls: ['./call-back-desktop.component.scss'],
})
export class CallBackDesktopComponent implements OnInit {
  public content = CALL_BACK_FORM;
  public contacts: IContactLink[] = CONTACT_LINKS;
  public isOpenned: boolean;

  ngOnInit() {
    this.isOpenned = false;
  }
}
