<aside>
  <section class="download">
    <div class="qr">
      <img src="/assets/images/qr.png" alt="qr-code" />
    </div>
    <app-button [content]="content.downloadApp.button"></app-button>
    <h2>{{ content.downloadApp.h2 | translate }}</h2>
    <div class="stores">
      <app-app-store [title]="content.downloadApp.p1"></app-app-store>
      <app-play-market [title]="content.downloadApp.p2"></app-play-market>
    </div>
  </section>
</aside>
