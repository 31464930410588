import { Component, OnInit } from '@angular/core';
import { DeviceOSEnum } from 'src/app/core/models';
import { NavService } from 'src/app/core/services/nav.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ScrollService } from 'src/app/core/services/scroll.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  public isScrolledToDown: boolean;

  public isMenuOpen: boolean = false;

  public get isIOS() {
    const deviceOs = this.deviceService.getDeviceInfo().os;
    return deviceOs === DeviceOSEnum.IOS || deviceOs === DeviceOSEnum.MAC
      ? true
      : false;
  }

  constructor(
    public readonly navService: NavService,
    private readonly scrollService: ScrollService,
    private readonly deviceService: DeviceDetectorService,
  ) {}

  public closeMenu() {
    this.navService.closeMenu();
  }

  ngOnInit() {
    this.initScrollService();
    this.navService.onMenuChanges$.subscribe(isOpen => this.isMenuOpen = isOpen);
  }

  private initScrollService() {
    this.scrollService.scroll$.subscribe(() => {
      this.isScrolledToDown = this.scrollService.isScrolledTo(1);
    });
  }
}
