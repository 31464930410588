import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-play-market',
  templateUrl: './play-market.component.html',
  styleUrls: ['./play-market.component.scss'],
})
export class PlayMarketComponent {
  @Input() public readonly title: string;
  public readonly link = environment.googlePlayLink;
}
