import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ITab } from 'src/app/core/models';
import { LanguageService } from 'src/app/core/services/language.service';
import { ScrollService } from 'src/app/core/services/scroll.service';

@Component({
  selector: 'app-mobile-content',
  templateUrl: './mobile-content.component.html',
})
export class MobileContentComponent implements OnInit {
  @Input() public readonly content: ITab;

  private videoPlayer: HTMLVideoElement;
  public activeLanguage: string;

  @ViewChild('videoPlayer')
  set mainVideoEl(el: ElementRef) {
    this.videoPlayer = el?.nativeElement;
  }

  constructor(
    private readonly languageService: LanguageService,
    private readonly scrollService: ScrollService,
  ) {}

  ngOnInit() {
    this.initVideos();
    this.activeLanguage = this.languageService.activeLang;
    this.languageService.onLanguageChanges$.subscribe((activeLang) => {
      this.activeLanguage = activeLang;
      this.videoPlayer?.load();
    });
  }

  protected initVideos() {
    this.scrollService.scroll$.subscribe((scrollTop) => {
      const offsetTop = this.scrollService.getOffsetTop(this.content.id);
      if (scrollTop > offsetTop - this.scrollService.winHeight / 5) {
        this.play();
      }
      if (scrollTop > offsetTop + this.scrollService.winHeight) {
        this.pause();
      }
    });
  }

  public play() {
    if (this.videoPlayer) {
      this.videoPlayer.muted = true; // without this line it's not working although I have "muted" in HTML
      this.videoPlayer.play();
    }
  }

  public pause() {
    if (this.videoPlayer) {
      this.videoPlayer.pause();
    }
  }

  public getImgPath(): string {
    return 'IMAGES.' + this.content.content.imgKey;
  }

  public getVideoPath(): string {
    return this.content.content.video + '--' + this.activeLanguage + '.mp4';
  }
}
