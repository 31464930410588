import { Component, OnInit } from '@angular/core';
import {
  CALL_BACK_FORM,
  IContactLink,
  CONTACT_LINKS,
} from 'src/app/core/models';

@Component({
  selector: 'app-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss'],
})
export class CallBackComponent implements OnInit {
  public content = CALL_BACK_FORM;
  public contacts: IContactLink[] = CONTACT_LINKS;

  constructor() {}

  ngOnInit(): void {}
}
