import { LogoComponent } from './logo/logo.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';

export const LAYOUTS = [
  LogoComponent,
  HeaderComponent,
  NavigationComponent,
  FooterComponent,
];
