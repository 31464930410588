import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceOSEnum } from 'src/app/core/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.scss'],
})
export class DownloadPageComponent implements AfterViewInit {
  public isDesktopDevice: boolean;
  public isIOS: boolean;
  public isLoading: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.isIOS = this.deviceDetector();
    this.isLoading = true;
  }

  ngAfterViewInit() {
    this.openMarketplace();
    setTimeout(() => (this.isLoading = false), 4000);
  }

  public openMarketplace() {
    if (this.isIOS) {
      window.open(environment.appStoreLink);
    } else {
      window.open(environment.googlePlayLink);
    }
  }

  protected deviceDetector() {
    const deviceOs = this.deviceService.getDeviceInfo().os;
    return deviceOs === DeviceOSEnum.IOS || deviceOs === DeviceOSEnum.MAC
      ? true
      : false;
  }
}
