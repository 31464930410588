import { Component } from '@angular/core';
import { ASIDE_CONTENT } from 'src/app/core/models';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent {
  public readonly content: any = ASIDE_CONTENT;
}
