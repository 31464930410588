<section
  [class.active]="active"
  [class.next]="!previous"
  [class.previous]="previous"
  [class]="activeLanguage"
>
  <div [id]="tabContent.id"></div>
  <div class="wrapper">
    <div class="media-content" (click)="onClick()">
      <div class="overflow-wrapper">
        <picture class="frame">
          <source srcset="/assets/images/tabs/phone-frame.png" />
          <img src="/assets/images/tabs/phone-frame.png" alt=" " />
        </picture>
        <app-bonus [isDesktop]="true"></app-bonus>
        <picture *ngIf="tabContent.content.imgKey" class="content">
          <source [srcset]="getImgPath() | translate" />
          <img [src]="getImgPath() | translate" alt="Mobile app Elegro" />
        </picture>
        <video *ngIf="tabContent.content.video" #videoPlayer class="content">
          <source [src]="getVideoPath()" type="" />
        </video>
      </div>
    </div>
    <h2>{{ tabContent.content?.h2 | translate }}</h2>
    <p
      *ngIf="
        tabContent.content?.p && (tabContent.content?.p | translate) !== 'null'
      "
      [class]="activeLanguage"
    >
      {{ tabContent.content?.p | translate }}
    </p>
    <p
      *ngIf="
        tabContent.content?.p1 &&
        (tabContent.content?.p1 | translate) !== 'null'
      "
    >
      {{ tabContent.content?.p1 | translate }}
    </p>
    <p
      *ngIf="
        tabContent.content?.p2 &&
        (tabContent.content?.p2 | translate) !== 'null'
      "
    >
      {{ tabContent.content?.p2 | translate }}
    </p>
    <table *ngIf="tabContent.content?.table">
      <caption>
        {{
          tabContent.content?.caption | translate
        }}
      </caption>
      <tr *ngFor="let row of tabContent.content?.table">
        <td>{{ row.title | translate }}</td>
        <td>{{ row.value | translate }}</td>
      </tr>
    </table>
    <div class="steps" *ngIf="tabContent.content?.steps">
      <ul class="list">
        <li *ngFor="let item of tabContent.content?.steps">
          <div class="icons">
            <img
              *ngFor="let icon of item.icons"
              [src]="icon"
              alt="step icons"
            />
          </div>
          <p>{{ item.desciption | translate }}</p>
          <p *ngIf="item.accomplice" class="accomplice">
            {{ item.accomplice | translate }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>
