import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-spoiler',
  templateUrl: './spoiler.component.html',
  styleUrls: ['./spoiler.component.scss'],
})
export class SpoilerComponent implements OnInit {
  @ViewChild('spoiler') private spoilerRef: ElementRef;

  @Input() public readonly content;

  @Output() open$ = new EventEmitter();

  public isOpen = false;

  public defaultHeight: string;

  public height: string;

  @HostListener('window:resize')
  public setDefaultHeight() {
    this.defaultHeight = window.innerWidth >= 1920 ? '40px' : '36px';
  }

  ngOnInit() {
    this.setDefaultHeight();
    this.height = this.defaultHeight;
  }

  public toggle() {
    const cashedOpen = this.isOpen;
    this.open$.emit();
    this.isOpen = !cashedOpen;
    setTimeout(() => {
      this.height = this.isOpen
        ? this.spoilerRef.nativeElement.scrollHeight + 'px'
        : this.defaultHeight;
    });
  }
}
