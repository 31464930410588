import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

declare let require: any;
const lottie: any = require('lottie-web/build/player/lottie.js');
@Component({
  selector: 'app-animated-svg',
  templateUrl: './animated-svg.component.html',
  styleUrls: ['./animated-svg.component.scss'],
})
export class AnimatedSvgComponent implements AfterViewInit {
  @Input() isFormOnFocus: boolean;

  @ViewChild('typingContainer') typingContainer: ElementRef;
  @ViewChild('reactingContainer') reactingContainer: ElementRef;
  @ViewChild('lookingContainer') lookingContainer: ElementRef;

  private typingAnim: any;
  private reactingAnim: any;
  private lookingAnim: any;
  public isTyping: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    this.isTyping = true;
  }

  ngAfterViewInit() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const typingOptions = {
      container: this.typingContainer.nativeElement,
      loop: true,
      autoplay: true,
      path: '/assets/animations/typing.json',
    };
    const reactingOptions = {
      container: this.reactingContainer.nativeElement,
      loop: false,
      autoplay: false,
      path: '/assets/animations/reacting.json',
    };
    const lookingOptions = {
      container: this.lookingContainer.nativeElement,
      loop: true,
      autoplay: true,
      path: '/assets/animations/looking.json',
    };

    this.typingAnim = lottie.loadAnimation(typingOptions);
    this.reactingAnim = lottie.loadAnimation(reactingOptions);
    this.lookingAnim = lottie.loadAnimation(lookingOptions);
  }

  onMouseEnter() {
    this.isTyping = false;
    this.reactingAnim.currentFrame = 0;
    this.reactingAnim.currentRawFrame = 0;
    this.reactingAnim.play();
  }
  onMouseLeave() {
    this.reactingAnim.play();
    this.isTyping = true;
  }
}
