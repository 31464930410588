import { Component, QueryList, ViewChildren } from '@angular/core';
import { faq } from 'src/app/core/models';
import { SpoilerComponent } from '../spoiler/spoiler.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent {
  @ViewChildren(SpoilerComponent)
  private spoilerQueryList!: QueryList<SpoilerComponent>;
  public readonly content = faq;

  public closeAll() {
    this.spoilerQueryList.forEach((item) => {
      if (item.isOpen) {
        item.isOpen = false;
        item.height = item.defaultHeight;
      }
    });
  }
}
