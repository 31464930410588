<div
  class="wrap"
  [class.active]="isOpen"
  [ngStyle]="{ 'max-height': height }"
  #spoiler
>
  <h5 (click)="toggle()">
    <span>{{ content?.title }}</span>
  </h5>
  <div class="content">
    <ng-container *ngIf="!content?.link">
      <p>{{ content?.p }}</p>
      <p *ngIf="content.p2">{{ content?.p2 }}</p>
      <p *ngIf="content.p3">{{ content?.p3 }}</p>
      <ul *ngIf="content.list">
        <li *ngFor="let i of content.list">{{ i }}</li>
      </ul>
    </ng-container>
    <ng-container *ngIf="content?.link">
      <p>
        {{ content?.p }} <a href="/">{{ content?.link }}</a
        >{{ content?.p2 }}
      </p>
    </ng-container>
  </div>
</div>
