<section [class]="content.id" class="container">
  <div [id]="content.id" class="anchor"></div>
  <div class="wrapper">
    <h2>{{ content.content?.h2 | translate }}</h2>
    <div class="media-content">
      <div class="overflow-wrapper">
        <picture class="frame" (click)="play()">
          <source srcset="/assets/images/tabs/phone-frame.png" />
          <img src="/assets/images/tabs/phone-frame.png" alt=" " />
        </picture>
        <picture *ngIf="content.content.imgKey" class="content">
          <source [srcset]="getImgPath() | translate" />
          <img [src]="getImgPath() | translate" alt="Mobile app Elegro" />
        </picture>
        <video
          *ngIf="content.content.video"
          #videoPlayer
          preload="auto"
          class="content"
          muted
          autoplay
          playsinline
        >
          <source [src]="getVideoPath()" type="" />
        </video>
      </div>
    </div>
    <p
      class="text"
      *ngIf="content.content?.p && (content.content?.p | translate) !== 'null'"
    >
      {{ content.content?.p | translate }}
    </p>
    <p
      class="text"
      *ngIf="
        content.content?.p1 && (content.content?.p1 | translate) !== 'null'
      "
    >
      {{ content.content?.p1 | translate }}
    </p>
    <p
      class="text text-last"
      *ngIf="
        content.content?.p2 && (content.content?.p2 | translate) !== 'null'
      "
    >
      {{ content.content?.p2 | translate }}
    </p>
    <div class="tariff-block" *ngIf="content.content?.table">
      <h3>
        {{ content.content?.caption | translate }}
      </h3>
      <div class="tariff" *ngFor="let item of content.content?.table">
        <p class="title">{{ item.title | translate }}</p>
        <p class="value">{{ item.value | translate }}</p>
      </div>
    </div>
    <div class="steps" *ngIf="content.content?.steps">
      <ul class="list">
        <li *ngFor="let item of content.content?.steps">
          <div class="icons">
            <img
              *ngFor="let icon of item.icons"
              [src]="icon"
              alt="step icons"
            />
          </div>
          <p>{{ item.desciption | translate }}</p>
          <p *ngIf="item.accomplice" class="accomplice">
            {{ item.accomplice | translate }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>
